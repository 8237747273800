<template>
    <header class="bg-blue-950 text-white flex items-center justify-between px-4 h-20">
        <div class="flex items-center">
            <span class="whitespace-nowrap">{{ userName }}</span>
        </div>
    
        <!-- Hamburger Icon for Mobile -->
        <div class="md:hidden">
            <button @click.stop="toggleMenu" class="text-white focus:outline-none">
                <svg class="w-6 h-6" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                    <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M4 6h16M4 12h16m-7 6h7"></path>
                </svg>
            </button>
        </div>
    
        <!-- Log Out Link Section (Hidden on Small Screens) -->
        <div class="hidden md:flex items-center space-x-2">
            <button @click="logout" class="flex items-center space-x-2 text-white hover:bg-gray-700 p-2 rounded">
                <svg class="w-5 h-5" xmlns="http://www.w3.org/2000/svg" width="1.2em" height="1.2em" viewBox="-5 -3 24 24">
                    <path fill="currentColor" d="M3.414 7.828h5.642a1 1 0 1 1 0 2H3.414l1.122 1.122a1 1 0 1 1-1.415 1.414L.293 9.536a.997.997 0 0 1 0-1.415L3.12 5.293a1 1 0 0 1 1.415 1.414zM13 0 a1 1 0 0 1 1 1v16a1 1 0 0 1-2 0V1a1 1 0 0 1 1-1" />
                </svg>
                <span>Odjava</span>
            </button>
        </div>
    </header>

    <!-- Mobile Menu -->
    <transition name="slide-fade">
        <!-- Dodato maksimalno ograničenje visine menija i skrolovanje -->
        <div v-show="menuOpen" class="menu-container absolute right-4 top-14 w-72 bg-teal-900 rounded-lg shadow-lg py-2 z-50 overflow-y-auto max-h-[80vh]">
            <ul class="space-y-2 text-left">
            <!-- Operator Menu Items -->
            <template v-if="auth.user.type === 'operator'">
                <li>
                    <button @click="toggleSubMenu" class="w-full text-white p-2 rounded flex items-center space-x-2 text-sm">
                        Upravljanje korisnicima
                    </button>
                    <!-- Sub Menu for Users -->
                    <ul v-show="subMenuOpen" class="pl-4 space-y-2">
                        <li>
                            <Link @click.stop="toggleMenu" href="/users/operator" class="w-full text-white p-2 rounded flex items-center space-x-2 text-sm">
                                Operatori
                            </Link>
                        </li>
                        <li>
                            <Link @click.stop="toggleMenu" href="/users/employer" class="w-full text-white p-2 rounded flex items-center space-x-2 text-sm">
                                Poslodavci
                            </Link>
                        </li>
                        <li>
                            <Link @click.stop="toggleMenu" href="/users/worker" class="w-full text-white p-2 rounded flex items-center space-x-2 text-sm">
                                Radnici
                            </Link>
                        </li>
                    </ul>
                </li>
                <!-- Operator Specific Menu Items -->
                <li v-for="(item, index) in operatorMainMenuItems" :key="index">
                    <Link @click="toggleMenu" :href="item.url" class="w-full text-white p-2 rounded flex items-center space-x-2 text-sm">
                        {{ item.label }}
                    </Link>
                </li>
            </template>
    
            <!-- Employer Menu Items -->
            <template v-else-if="auth.user.type === 'employer'">
                <li>
                    <Link @click="toggleMenu" href="/employers/profile" class="w-full text-white p-2 rounded flex items-center space-x-2 text-sm">Profil poslodavca</Link>
                </li>

                <template v-if="isEmployerProfileCreated">
                    <li><Link @click="toggleMenu" href="/employers/facility" class="w-full text-white p-2 rounded flex items-center space-x-2 text-sm">Lokali</Link></li>             
                    <li><Link @click="toggleMenu" href="/employers/advertisement" class="w-full text-white p-2 rounded flex items-center space-x-2 text-sm">Oglasi</Link></li> 
                </template>
            </template>
    
            <!-- Worker Menu Items -->
            <template v-else-if="auth.user.type === 'worker'">
                <li>
                    <Link @click.stop="toggleMenu" href="/workers/profile" class="w-full text-white p-2 rounded">Profil radnika</Link>
                </li>
    
                <template v-if="isWorkerProfileCreated">
                <li>
                    <Link @click="toggleMenu" href="/workers/cv" class="w-full text-white p-2 rounded flex items-center space-x-2">
                        CV
                    </Link>
                </li>
                <li>
                    <Link @click="toggleMenu" href="/workers/experience" class="w-full text-white p-2 rounded flex items-center space-x-2">
                        Radno Iskustvo
                    </Link>
                </li>
                <li>
                    <button @click="togglePhotoMenu" class="w-full text-white p-2 rounded flex items-center space-x-2 text-sm">
                    Fotografije
                    </button>
                    <!-- Submenu for Photos -->
                    <ul v-show="photoMenuOpen" class="pl-4 space-y-2">
                        <li>
                            <Link @click.stop="toggleMenu" href="/workers/avatar" class="w-full text-white p-2 rounded flex items-center space-x-2 text-sm">
                            Avatar
                            </Link>
                        </li>
                        <li>
                            <Link @click.stop="toggleMenu" href="/workers/photos" class="w-full text-white p-2 rounded flex items-center space-x-2 text-sm">
                            Dodatne fotografije
                            </Link>
                        </li>
                    </ul>
                </li>
                </template>
            </template>
    
            <!-- Logout Button -->
            <li>
                <button @click="logout" class="w-full flex items-center space-x-2 text-white p-2 rounded text-sm">
                    <svg class="w-5 h-5" xmlns="http://www.w3.org/2000/svg" width="1.2em" height="1.2em" viewBox="-5 -3 24 24">
                        <path fill="currentColor" d="M3.414 7.828h5.642a1 1 0 1 1 0 2H3.414l1.122 1.122a1 1 0 1 1-1.415 1.414L.293 9.536a.997.997 0 0 1 0-1.415L3.12 5.293a1 1 0 0 1 1.415 1.414zM13 0 a1 1 0 0 1 1 1v16a1 1 0 0 1-2 0V1a1 1 0 0 1 1-1" />
                    </svg>
                    <span>Odjava</span>
                </button>
            </li>
            </ul>
        </div>
    </transition>
</template>

<script setup>
import { ref, onMounted, onUnmounted } from 'vue';
import { useForm, usePage } from '@inertiajs/vue3';
import axios from 'axios';

const { auth } = usePage().props;

const userName = `${auth.user.name} ${auth.user.surname}`;

const menuOpen = ref(false);
const subMenuOpen = ref(false);
const photoMenuOpen = ref(false);

const operatorMainMenuItems = ref([
    { label: 'Tipovi poslova', url: '/jobTypes' },
    { label: 'Tipovi objekata', url: '/facilityTypes' },
    { label: 'Jezici', url: '/languages' },
]);
/*
const employerMainMenuItems = ref([
  { label: 'Profil poslodavca', url: '/employers/profile' },
  { label: 'Lokali', url: '/employers/facility' },
  { label: 'Oglasi', url: '/employers/advertisement' },
]);

const workerMainMenuItems = ref([
  { label: 'Profil radnika', url: '/workers' }
]);
*/
const toggleMenu = (event) => {
    event.stopPropagation();
    menuOpen.value = !menuOpen.value;
};

const toggleSubMenu = () => {
    subMenuOpen.value = !subMenuOpen.value;
};

const closeSubMenu = () => {
    subMenuOpen.value = false;
};

const togglePhotoMenu = () => {
    photoMenuOpen.value = !photoMenuOpen.value;
};

const handleMainMenuClick = () => {
    closeSubMenu();
};

const logoutForm = useForm({});

const logout = async () => {
    try {
        logoutForm.post(route('logout'), {
            onFinish: () => {
                window.location.reload();
            }
        });
    } catch (error) {
        console.error('Logout failed:', error);
    }
};

document.addEventListener('click', (event) => {
    if (!event.target.closest('.menu-container')) {
        menuOpen.value = false;
        subMenuOpen.value = false;
    }
});

window.addEventListener('resize', () => {
    if (window.innerWidth >= 768) {
        menuOpen.value = false;
        subMenuOpen.value = false;
    }
});

const isWorkerProfileCreated = ref(false);
const workerProfileManuallyCreated = ref(false);

const isEmployerProfileCreated = ref(false);
const employerProfileManuallyCreated = ref(false);

// Function that checks the status of the worker's profile from the backend
const fetchWorkerProfileStatus = async () => {
    try {
        const response = await axios.get('/api/worker-profile-status');
        isWorkerProfileCreated.value = response.data.isCreated;  // Received status from backend
    } catch (error) {
        console.error('Error fetching worker profile status:', error);
    }
};

// Function that checks if the user has a worker profile created
const checkWorkerProfile = () => {
    if (auth.user.worker || workerProfileManuallyCreated.value) {
        isWorkerProfileCreated.value = true; // If the profile exists, set to true
    } else {
        fetchWorkerProfileStatus();  // Call API to check status if not created
    }
};

// A function that checks the status of the employer's profile from the backend
const fetchEmployerProfileStatus = async () => {
    try {
        const response = await axios.get('/api/employer-profile-status');
        isEmployerProfileCreated.value = response.data.isCreated;  // Received status from backend
    } catch (error) {
        console.error('Error fetching employer profile status:', error);
    }
};

// Function that checks if the user has a employer profile created
const checkEmployerProfile = () => {
    if (auth.user.employer || employerProfileManuallyCreated.value) {
        isEmployerProfileCreated.value = true; // If the profile exists, set to true
    } else {
        fetchEmployerProfileStatus();  // Call API to check status if not created
    }
};

onMounted(() => {
    checkWorkerProfile();    
    window.addEventListener('worker-profile-created', () => {
        workerProfileManuallyCreated.value = true;
        checkWorkerProfile();
    });

    checkEmployerProfile();
    window.addEventListener('employer-profile-created', () => {
        employerProfileManuallyCreated.value = true;
        checkEmployerProfile();
    })   
});

onUnmounted(() => {
    window.removeEventListener('worker-profile-created', checkWorkerProfile);
});
</script>

<style scoped>
/* Animacija za otvaranje/zatvaranje menija */
.slide-fade-enter-active, .slide-fade-leave-active {
    transition: opacity 0.3s, transform 0.3s;
}
.slide-fade-enter, .slide-fade-leave-to {
    opacity: 0;
    transform: translateY(-10px);
}

/* Prilagođavanje stilova za opcije u meniju */
a, button {
    display: flex;
    align-items: center;
    width: 100%;
    padding: 0.5rem 1rem;
    color: white;
    text-decoration: none;
    background: transparent;
    border: none;
    cursor: pointer;
    position: relative; /* For pseudo-element positioning */
    border-radius: 8px; /* Rounded corners */
    font-size: 0.875rem; /* Smanjena veličina fonta (ekvivalentno text-sm u Tailwindu) */
}

/* Hover efekat za opcije u meniju */
a::before, button::before {
    content: '';
    position: absolute;
    top: 0;
    left: 10px; /* Left margin */
    right: 10px; /* Right margin */
    bottom: 0;
    background-color: rgba(107, 114, 128, 0.5); /* Semi-transparent grey */
    z-index: -1; /* Behind the text */
    border-radius: 8px; /* Rounded corners */
    display: none; /* Hide initially */
}
a:hover::before, button:hover::before {
    display: block; /* Show on hover without animation */
}
</style>
